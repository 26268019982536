import {openDB, deleteDB, wrap, unwrap} from 'idb'

const db = await openDB('zhiyan_db', 1, {
  upgrade(db) {
    db.createObjectStore('app_store')
  }
})

function IDB() {
  this.put = async (key, value) => {
    await db.put('app_store', value, key)
  }

  // same as put, alias
  this.set = async (key, value) => {
    await db.put('app_store', value, key)
  }

  this.get = async (key) => {
    return await db.get('app_store', key)
  }

  this.delete = async (key) => {
    await db.delete('app_store', key)
  }

  this.keys = async () => {
    return await db.getAllKeys('app_store')
  }

  this.clear = async () => {
    await db.clear('app_store')
  }
}

let _idb = new IDB()

export default _idb
