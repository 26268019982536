import {createApp, ref} from 'vue'
import App from './App.vue'
import './style/main.scss'
import {store} from './utils/store.js'
import router from './utils/router.js'

import {registerSW} from 'virtual:pwa-register'
import {notify} from './utils/common.js'

let doingUpdate = ref(false)

const updateSW = registerSW({
  async onNeedRefresh() {

    if (doingUpdate.value) {
      return false
    }

    let p = confirm('检测到远程内容有更新，是否重新载入？')

    if (!p) {
      return false
    }

    doingUpdate.value = true

    await updateSW()
  },
  onOfflineReady() {
    console.log('offline ready')
  }
})

if (location.hostname === 'zy.jw1.dev') {
  let originalHref = location.href
  let newHref = originalHref.replace('zy.jw1.dev', 'www.zhiyan.dev')
  location.href = newHref
}


setTimeout(function () {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // dark mode
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#121212')
  } else {
    // light mode
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff')
  }
}, 50)


window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
  const newColorScheme = event.matches ? 'dark' : 'light'
  console.log(newColorScheme)

  document.querySelector('meta[name="theme-color"]').setAttribute('content', newColorScheme === 'dark' ? '#121212' : '#ffffff')
})

const app = createApp(App)

app.use(store)
app.use(router)
app.mount('#app')
