import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'app',
    component: () => import('../views/app-view.vue')
  },
  {
    path: '/s',
    name: 'shared conversation',
    component: () => import('../views/shared-conversations.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
