import {createStore} from 'vuex'

const store = createStore({
  state() {
    return {
      appIsReady: false,
      maintaining: false,
      isLogin: false,
      notifications: {},
      shouldCutConversations: true,
      loggingOut: false,
      cutCount: 10,
      systemInfo: '',
      shareLink: '',
      who: '',
      requestSequence: -1,
      inPageTokens: 0,
      checkingLogin: true,
      sidebarExpanded: false,
      showAddCustomShortcutsPanel: false,
      messages: [],
      streaming: false,
      userInput: '',
      editIndex: '',
      editMessage: '',
      searchingOnline: false,
      userIsComposting: false,
      composeMessagesTrigger: 0,
      fromReGen: false,
      inputOnFocus: false,
      inputOnBlur: false,
      chatMode: {
        index: '',
        title: '',
        instructions: '',
        prefix: '',
        suffix: '',
        noHistory: false,
        desc: ''
      },
      customModeExpand: false,
      reReadLocalModelIndexTrigger: 0,
      historyChangeIndicator: 0,
      prepareMessageImages: [],
      clearImagesTrigger: 0,
      streamTimeoutCount: 0,
      applyingHistory: false,
      sharing: false,
      scrollDownTrigger: 0,
      userInteracted: false,
      showExamples: true,
      defaultRenderHTML: true,
      isPWA: false,
      isMobile: false,
      selectedImageRecognitionService: 'gpt-4o-mini',
      imageRecognitionService: [
        {
          name: 'gpt-4o-mini',
          api: '/api/v1/image-recog/gpt-4o-mini'
        },
        {
          name: 'gpt-4o',
          api: '/api/v1/image-recog/gpt-4o'
        },
        {
          name: 'claude-3-haiku',
          api: '/api/v1/image-recog/claude-haiku'
        },
        {
          name: 'gpt-4-turbo',
          api: '/api/v1/image-recog/gpt-4-turbo'
        }
      ],
      currentModel: {
        index: '',
        name: '',
        platform: '',
        searchSupport: false,
        limit: 0,
        betaOnly: false,
        model: '',
        logo: '',
        url: '',
        desc: '',
        functions: [],
        priceRatio: {
          prompt: 0,
          completion: 0
        },
        score: {
          quality: 0,
          speed: 0
        },
        hasVision: false
      }
    }
  },
  mutations: {
    setSideBarExpanded(state, expanded) {
      state.sidebarExpanded = expanded
    },
    setCurrentModel(state, model) {
      state.currentModel = model
    },
    pushNotification(state, notification) {
      let id = Date.now()
      state.notifications[id] = notification
    }
  }
})

export {
  store
}
